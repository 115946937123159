import React, { useState, useEffect, useRef } from "react";
import HamburgerButton from "./Hamburger";
import NavbarLogo from "./NavbarLogo";
import { useLocation, useNavigate } from "react-router-dom";

const NavbarNew = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [active, setActive] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  let subMenuRef = useRef(null);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 1024px)");

    function handleClickOutside(event) {
      if (subMenuRef.current && !subMenuRef.current.contains(event.target)) {
        setActiveSubMenu(null);
      }
    }

    if (mediaQuery.matches) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    const handleMediaQueryChange = (event) => {
      if (event.matches) {
        document.addEventListener("mousedown", handleClickOutside);
      } else {
        document.removeEventListener("mousedown", handleClickOutside);
      }
    };

    mediaQuery.addEventListener("change", handleMediaQueryChange);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);

  const handleLinkClick = (path) => {
    setActive(false);
    setActiveSubMenu(null);
    navigate(path);
  };

  const PhoneMenu = ({ el, isActive }) => {
    if (isActive) {
      return (
        <div>
          <span
            onClick={() => handleLinkClick(el.path)}
            className="hover:text-[#ed2225] hover:scale-110 transition-transform duration-300 cursor-pointer"
          >
            <p
              className={`px-2 ${
                location.pathname === el.path ? "text-[#ed2225]" : ""
              }`}
            >
              {el.name}
            </p>
          </span>
          {el.subItems &&
            el.subItems.map((subItem) => (
              <li
                className="px-2 py-1 rounded-sm hover:bg-slate-100 text-center"
                key={subItem.path}
              >
                <span
                  onClick={() => handleLinkClick(subItem.path)}
                  className="text-lg hover:text-[#ed2225] hover:scale-110 transition-transform duration-300 cursor-pointer"
                >
                  <p> {subItem.name}</p>
                </span>
              </li>
            ))}
        </div>
      );
    }
    return (
      <span
        onClick={() => handleLinkClick(el.path)}
        className="hover:text-[#ed2225] hover:scale-110 transition-transform duration-300 cursor-pointer"
      >
        <p
          className={`px-2 ${
            location.pathname === el.path ? "text-[#ed2225]" : ""
          }`}
        >
          {el.name}
        </p>
      </span>
    );
  };

  return (
    <div className="container">
      <div
        className={`z-40 fixed top-0 transition-top duration-700 ease-in-out left-0 right-0 w-full
        lg:w-max bg-opacity-50 backdrop-blur-lg lg:backdrop-blur-0 h-14
        flex lg:flex-row justify-between px-2 lg:max-w-7xl items-center`}
      >
        <div onClick={() => setActive(false)}>
          <NavbarLogo link />
        </div>

        <div className="flex gap-3 justify-center items-center lg:hidden p-1 rounded-md">
          <HamburgerButton active={active} onClick={() => setActive(!active)} />
        </div>
      </div>

      <ul
        className={`z-30 fixed top-0 transition-top duration-700 ease-in-out lg:right-0 w-full lg:bg-opacity-50 backdrop-blur ${
          active ? "translate-x-0 text-black text-xl" : "-translate-x-full"
        } lg:translate-x-0 h-[calc(100vh_-_3rem)] lg:h-24 mt-12 flex flex-col justify-center lg:flex-row gap-4 text-2xl lg:text-lg text-center items-center lg:mt-0 font-medium`}
      >
        {navbar.map((el) => (
          <li
            key={el.path || el.name}
            className={`relative
              ${
                location.pathname === el.path
                  ? "text-[#ed2225] border-b-2 border-[#ed2225] py-[2px] font-semibold"
                  : "text-black"
              } 
            `}
          >
            {el.subItems ? (
              <div>
                <span
                  onClick={() =>
                    setActiveSubMenu((prev) =>
                      prev === el.name ? null : el.name
                    )
                  }
                  className={
                    location.pathname === el.path
                      ? "text-[#ed2225] border-b-2 border-[#ed2225] font-semibold hover:cursor-pointer"
                      : "text-black hover:text-[#ed2225] hover:border-b-2 hover:border-[#ed2225] font-semibold hover:cursor-pointer"
                  }
                >
                  {el.name}
                </span>
                {activeSubMenu === el.name && (
                  <>
                    {/* small to md*/}
                    <ul
                      ref={subMenuRef}
                      className={`submenu block lg:hidden mt-3 transition-transform duration-300 ${
                        activeSubMenu === el.name ? "open" : ""
                      }`}
                    >
                      {el.subItems.map((subItem) => (
                        <li
                          className="px-2 py-1 rounded-sm hover:bg-slate-100 text-left bg-[#fffbfb]"
                          key={subItem.path}
                        >
                          <span
                            onClick={() => handleLinkClick(subItem.path)}
                            className="text-lg hover:text-[#ed2225] hover:scale-110 transition-transform duration-300 cursor-pointer"
                          >
                            {subItem.name}
                          </span>
                        </li>
                      ))}
                    </ul>

                    {/* lg */}
                    <ul
                      ref={subMenuRef}
                      className="hidden lg:block mt-1 absolute top-full w-max bg-[#fffbfb] border border-gray-200 p-3 shadow-lg rounded-md opacity-100 pointer-events-auto transition-opacity duration-300"
                    >
                      {el.subItems.map((subItem) => (
                        <li
                          className="px-2 py-1  hover:bg-slate-100 rounded-md text-left"
                          key={subItem.path}
                        >
                          <span
                            onClick={() => handleLinkClick(subItem.path)}
                            className="text-lg hover:text-[#ed2225] hover:scale-110 transition-transform duration-300 cursor-pointer"
                          >
                            {subItem.name}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </div>
            ) : (
              <PhoneMenu el={el} isActive={active} />
            )}
          </li>
        ))}

        <a
          href="https://drive.google.com/file/d/1t1VZkH8GhaoXGDsJT_TUupBY_hHREuVN/view"
          target="_blank"
          rel="noreferrer"
          className="bg-[#ED2025] text-white border rounded-full pl-3 pr-3 pt-2 pb-2 mt-5 :mt-0 lg:absolute lg:right-2"
        >
          View Profile
        </a>
      </ul>
    </div>
  );
};

export default NavbarNew;

const navbar = [
  {
    name: "HOME",
    path: "/",
  },
  {
    name: "ABOUT US",
    subItems: [
      { name: "Our Team", path: "/our-team" },
      { name: "Mission & Vision", path: "/mission&vision" },
    ],
  },
  {
    name: "PRODUCTS",
    subItems: [
      { name: "Knit", path: "/products/knit" },
      { name: "Woven", path: "/products/woven" },
      { name: "Sweater", path: "/products/sweater" },
    ],
  },
  {
    name: "MANUFACTURING",
    path: "/manufacture",
  },
  {
    name: "SISTER CONCERN",
    subItems: [
      {
        name: "Pole Star International (PVT) Ltd.",
        path: "/",
      },
      {
        name: "Pole Star Fashion Design Ltd.",
        path: "/sister-concern/Pole-Star-Fashion-Design-LTD",
      },
      {
        name: "Pole Star Knit Composite Ltd.",
        path: "/sister-concern/Pole-Star-Knit-Composite-LTD",
      },
      {
        name: "Prime Sweater Ltd.",
        path: "/sister-concern/Prime-Sweater-LTD",
      },
      {
        name: "Pole Star Fashion Ltd.",
        path: "/",
      },
      {
        name: "Pole Star Printing & Embroidery",
        path: "/",
      },
      { name: "BD Knit Design Ltd.", path: "/sister-concern/BD-Knit-Design" },
    ],
  },
  {
    name: "QUALITY ASSURANCE",
    path: "/quality-assurance",
  },
  {
    name: "CONTACT",
    path: "/contact",
  },
];
