import React from "react";
import { medialUrl } from "../EndPoints";
import { Link } from "react-router-dom";

const HeroLeftContent = () => {
  const companies = [
    {
      name: "Pole Star International (PVT) Ltd.",
      pathLink: "/",
      subCompanies: [],
    },
    {
      name: "Pole Star Fashion Design Ltd.",
      pathLink: "/sister-concern/Pole-Star-Fashion-Design-LTD",
      subCompanies: [],
    },
    {
      name: "Pole Star Knit Composite Ltd.",
      pathLink: "/sister-concern/Pole-Star-Knit-Composite-LTD",
      subCompanies: [],
    },
    {
      name: "Prime Sweater Ltd.",
      pathLink: "/sister-concern/Prime-Sweater-LTD",
      subCompanies: [],
    },
    {
      name: "Pole Star Fashion Ltd.",
      pathLink: "/",
      subCompanies: [],
    },
    {
      name: "Pole Star Printing & Embroidery",
      pathLink: "/",
      subCompanies: [],
    },
    {
      name: "BD Knit Design Ltd.",
      pathLink: "/sister-concern/BD-Knit-Design",
      subCompanies: [],
    },
  ];
  return (
    <div className="absolute bg-black/50 bottom-12 md:bottom-16 right-2 md:right-5 flex flex-row gap-1 text-white backdrop-blur p-2 rounded-xl">
      <div className="font-semibold text-left px-2">
        {companies.map((company, index) => (
          <div key={index}>
            <span className="hover:underline hover:underline-offset-2 hover:cursor-pointer text-lg">
              <Link to={company.pathLink}>{company.name}</Link>
            </span>
            {company.subCompanies.length > 0 && (
              <ul
                className="font-light ml-5"
                style={{ listStyleType: "disc", marginLeft: "20px" }}
              >
                {company.subCompanies.map((subCompany, subIndex) => (
                  <li key={subIndex}>{subCompany}</li>
                ))}
              </ul>
            )}
          </div>
        ))}
      </div>
      <img
        src={`${medialUrl}/img/Home/home-hero.jpg`}
        className="rounded-full w-10 h-10"
        alt=""
      />
    </div>
  );
};

export default HeroLeftContent;
